import React, { useState } from "react"
import { ModalType, ResponseStatus } from "../utils/constants"
import { LoadingOverlay } from "../elements/shared/wrappers"
import ProcessLoading from "../components/ProcessLoading"
import { BodyText } from "../elements/shared/texts"
import Layout from "../components/Layout"
import LogIn from "../components/LogIn"
import SignUp from "../components/SignUp"
import ForgotPassword from "../components/ForgotPassword"
import ProfileHeader from "../components/ProfileHeader"
import styled from "styled-components"
import { UseGlobalStateContext } from "../context/globalContext"
import { IsBrowser } from "../helpers/utils"
import { navigate } from "gatsby"
import { NarrowWrapper } from "../components/shared/NarrowWrapper"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import StorageService from "../helpers/StorageService";


const Wrapper = styled.div`
  
`

const AuthModal = () => {

  const [modal, setModal] = useState(ModalType.LOGIN)
  const [isProcessing, setProcessing] = useState(false)
  const [response, setResponse] = useState(null)
  const { userData } = UseGlobalStateContext()
  const metadata = StorageService.getService().getConfig()
  const [headerContent, setHeaderContent] = useState(
    {
      title: 'Login',
      description: 'Provide your email and password to get into your account.'
    }
  )

  if (userData) {
    if (IsBrowser) {
      navigate("/account/dashboard")
    }
    return null
  }


  const showSignUp = () => {
    setModal(ModalType.SIGNUP)
    setHeaderContent({
      title: 'Sign up',
      description: 'We need you to provide a valid email, we will send you a verification link. You will not be able to use you account without verifying your email'
    })
  }

  const showLogIn = () => {
    setModal(ModalType.LOGIN)
    setHeaderContent({
      title: 'Login',
      description: 'Provide your email and password to get into your account.'
    })
  }
  const showForgotPassword = () => {
    setModal(ModalType.FORGOT_PASSWORD)
    setHeaderContent({
      title: 'Reset password',
      description: 'Please type in your email address in order to initiate the "reset password" process.'
    })
  }

  const showEmailConfirmation = () => {
    setModal(ModalType.EMAIL_CONFIRMATION)
    setHeaderContent({
      title: 'Email confirmation',
      description: 'Please type in your email address to receive a confirmation email.'
    })
  }

  const onClosePopup = () => {
    switch (modal) {
      case ModalType.SIGNUP:
        if (response) {
          if (response.status === ResponseStatus.Success) {
            showLogIn()
          }
        }
        break;
      default:
        console.log("Case not handled")
    }
  }

  return (
    <Layout >
      <GoogleReCaptchaProvider reCaptchaKey={metadata.recaptcha_site_key}>

      {isProcessing &&
      <LoadingOverlay id={"loadingOverlay"} onClick={(e) => {
        if (e.target.parentElement.id === "loadingOverlay") {
          setProcessing(false)
          setResponse(null)
          onClosePopup()
        }
      }}>
        <ProcessLoading
          id={"processLoading"}
          response={response}
          setResponse={setResponse}
          setProcessing={setProcessing}
          modelType={modal}
          onClose={onClosePopup}
        />
      </LoadingOverlay>
      }
      <NarrowWrapper isProcessing={isProcessing}>
        <div className={"mx-auto"}>
        <ProfileHeader content={headerContent} />
        <BodyText>
            <div >
              <div className={"container mx-auto"}>
                  <Wrapper>
                          { modal === ModalType.LOGIN && <LogIn  setProcessing={setProcessing} setResponse={setResponse} showSignUp={showSignUp} showForgotPassword={showForgotPassword} showEmailConfirmation={showEmailConfirmation} /> }
                          { modal === ModalType.SIGNUP && <SignUp  setProcessing={setProcessing} setResponse={setResponse} showSignIn={showLogIn} />}
                          { modal === ModalType.FORGOT_PASSWORD && <ForgotPassword  setProcessing={setProcessing} setResponse={setResponse} showLogIn={showLogIn} requestType={ModalType.FORGOT_PASSWORD} />}
                          { modal === ModalType.EMAIL_CONFIRMATION && <ForgotPassword  setProcessing={setProcessing} setResponse={setResponse} showLogIn={showLogIn} requestType={ModalType.EMAIL_CONFIRMATION} />}
                  </Wrapper>

                </div>
            </div>
        </BodyText>
        </div>
      </NarrowWrapper>
      </GoogleReCaptchaProvider>
    </Layout>
  )
}

export default AuthModal